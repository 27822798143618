<template>
  <UiPage
    :title="$t('app.page.public-offer')"
    :titleTemplate="$t('app.page.public-offer')"
    class="content-padding-mobile text mobile-small-head"
  >
    <template #top>
      <BackButton />
    </template>

    <template #content>
      <div
        class="text"
        v-html="data.text"
      />
    </template>
  </UiPage>
</template>

<script>
import UiPage from '@/ui/Page.vue'
import BackButton from '@/ui/buttons/BackButton.vue'

export default {
  components: {
    UiPage,
    BackButton
  },
  data () {
    return {
      mountFetch: false,
      data: {}
    }
  },
  activated () {
    if (this.mountFetch) {
      this.fetch()
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const resp = await this.$axios.get(
          'shared/pages/public-offer')

        if (resp.status === 200) {
          this.data = resp.data.data
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.mountFetch = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.text {
  width: 100%;
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
}
</style>
