<template>
  <div class="page">
    <div
      v-if="isTop"
      class="page__top"
      :style="{
        'margin-bottom' : `${marginBottom}px`
      }"
    >
      <div class="page__top__title">
        <h2>
          {{ title }}
        </h2>
      </div>

      <slot
        v-if="!$store.state.window.to768"
        name="top"
      />
    </div>

    <div class="page__content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    titleTemplate: {
      type: String,
      default: ''
    },
    marginBottom: {
      type: Number,
      default: 30
    },
    isTop: {
      type: Boolean,
      default: true
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    document.title = `${this.$route.meta.title ? this.$t(this.$route.meta.title) : this.titleTemplate} | OnliX` || 'OnliX'
  }
}
</script>

<style lang="scss" scoped>
.page {
  min-height: 100vh;
  width: 100%;
  margin-top: 25px;
  padding: 0 40px;

  .page__top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .page__top__title {
      h2 {
        color: rgb(32, 37, 57);
        line-height: 100%;
        font-family: SemiBold;
      }
    }
  }

  &.mobile-small-head {
    .page__top {
      .page__top__title {
        h2 {
          font-size: 26px;
        }
      }
    }
  }

  .page__content {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .page {
    padding-top: 80px;
    padding-bottom: 105px;
  }
}

@media screen and (max-width: 768px) {
  .page {
    margin: 0;
    padding-top: 20px;
    padding-bottom: 125px !important;
    padding-right: 0;
    padding-left: 0;

    .page__top {
      margin-bottom: 15px !important;

      .page__top__title {
        padding: 0 17px;
      }
    }

    &.null {
      padding: 0 0 105px 0;

      .page__top {
        display: none;
      }
    }

    &.content-padding-mobile {
      .page__content {
        padding: 0 17px;
      }
    }
  }
}
</style>
